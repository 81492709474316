import React, { useEffect, useContext, useState, useRef } from "react"
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
import Loading from "react-js-loader";
import { Context as ArtContext } from '../../context/ArtContext';
import { Context as ProductContext } from '../../context/ProductContext';
import { Context as UserContext } from '../../context/UserContext';
import "./typeSelect.css"
import Popup from "reactjs-popup";
import { MetaTags } from 'react-meta-tags';
import PaperF from '../../assets/Home/Paper-frame.png'
import CanvasF from '../../assets/Home/Canvas-frame.png'
import Paper from '../../assets/Home/Paper.jpg'
import Canvas from '../../assets/Home/Canvas.jpg'
import FrameEditPreview from '../../components/frame/frameEditPreview';
import FramePopup from '../../components/frame/framePopup';
import axios from "axios";
import { base_url } from "../../helpers/baseURL";
import Price from '../../helpers/price';
import { useAlert } from "react-alert";
import Review from "../../components/checkout/review";
import Share from "../../components/share/share";
import Loader from "../../components/checkout/loader";
import Tools from "../../components/tools/tools";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


const TypeSelect = () => {
    const { state, setRatio, getSingleArt, calculatePrice, calculateNewDPI, zoomOpen, setCropImg, setPopImg, setPadding, cartTrig, getFrames, getSingleFrame, setFrames, clearSize } = useContext(ArtContext);
    const PContext = useContext(ProductContext);
    const UContext = useContext(UserContext)
    const history = useHistory();
    let params = useParams();
    const location = useLocation()
    const query = useQuery()
    const [artdatas, setartdatas] = useState("")
    const [title, setTitle] = useState("")
    const [selected, setselected] = useState("")
    const [artist, setArtist] = useState('')
    const [meta_artist, setMetaArtist] = useState('')
    const [zoom, openZoom] = useState(false)
    const [type, setType] = useState("frame")
    const [paper, setpaper] = useState("Canvas")
    const [frameid, setFrameid] = useState("")
    const [framename, setFrameName] = useState("")
    const [hoveredframe, hoverframe] = useState("")
    const [continuestatus, setcontinue] = useState(false)
    const [artInfo, setartInfo] = useState('')
    const [value, setValue] = useState(0)
    const [backimg, setBackImg] = useState()
    const [listed, setListed] = useState(false)
    const [inch, setInch] = useState()
    const [top, setTop] = useState()
    const [left, setLeft] = useState()
    const [right, setRight] = useState()
    const [orientation, setOrient] = useState("")
    const [framesize, setframe] = useState(0.8)
    const [dimension, setdimension] = useState("")
    const [thumb, setThumb] = useState("2")
    const [options, setOptions] = useState([]);
    const [matting, setmatt] = useState(true)
    const [finalsize, setfinal] = useState()
    const [slidervalue, setslider] = useState("")
    const [pin, changepin] = useState('')
    const [pinstatus, setpinstatus] = useState(false)
    const [loader, setloader] = useState(false)
    const [sticky, setSticky] = useState(false)
    const [add, openAdd] = useState(false)
    const [policy, setpolicy] = useState('')
    const [care, setCare] = useState([])
    const alert = useAlert()
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    useEffect(() => {
        setCropImg("")
        setartdatas(null)
        getSingleArt(null)
        PContext.getPolicy()
            .then(resp => {
                console.log("getting return policy", resp)
                setpolicy(resp)
            })
            .catch(err => {
                console.log("error on Return Policy")
            })
        PContext.getArtCare()
            .then(resp => {
                let data = resp?.find(x => x.type === `${location.pathname === "/custom-framing" ? "custom" : "art"}_${paper}`)?.data
                setCare(resp)
            })
            .catch(err => {
                console.log("error on Care instruction", err)
            })
        window.scrollTo(0, 0)
        if (localStorage.getItem("pincode")) {
            changepin(localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "")
            checkpin(localStorage.getItem("pincode"))
        }
    }, [])
    useEffect(() => {

    })
    useEffect(() => {
        if (params.artid) {
            getSingleArt(params.artid, "Paper")
                .then(res => {
                    var image = new Image();
                    console.log('response of art', res)
                    setartdatas(res.artData)
                    let title = res.artData?.data?.attributes.title
                    setTitle(title)
                    setCropImg(res.url)
                    setPopImg(res.pop_img)
                    setMetaArtist(res.artist)
                    let artist = res.artist.split(",").reverse()
                    console.log("reversed artist", artist)
                    setArtist(`${artist.toString()}`)
                    localStorage.setItem("artData", JSON.stringify(res.artData))
                    localStorage.setItem('frameImage', res.url)
                    //Set the Base64 string return from FileReader as source.
                    image.src = res.url;

                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = this.naturalHeight;
                        var width = this.naturalWidth;
                        localStorage.setItem("ratio", width / height)
                        localStorage.removeItem("user_upload")
                        const final = Object.assign(res, { "rheight": height, "rwidth": width })
                        setartInfo(final)
                        calculateNewDPI(res.height, res.width, "frame", history, 'art', paper, height, width)
                    };
                    PContext.checkWish(res.artData?.data?.id, "art")
                        .then(resp => {
                            setListed(resp)
                        })
                        .catch(err => {
                            console.error("error on check in wish", err)
                        })
                    console.log("art details", res.artData)
                })
                .catch(err => {
                    console.log("error on art load", err)
                    history.push('/404s')
                })
        }
        else if (query.get("id")) {
            console.log("calling with id")
            axios.get(base_url + `/jsonapi/file/file/${query.get("id")}`)
                .then(
                    res => {
                        console.log("cropped get image", base_url + res.data.data.attributes.uri.url)
                        setCropImg(base_url + res.data.data.attributes.uri.url)
                        setPopImg(base_url + res.data.data.attributes.uri.url)
                        localStorage.setItem("showimage", base_url + res.data.data.attributes.uri.url)
                        localStorage.setItem("user_upload", true)
                        const img = new Image();
                        calculateNewDPI(query.get("height"), query.get("width"), "crop", history, "upload", query.get("height"), query.get("width"))
                        img.src = base_url + res.data.data.attributes.uri.url;
                        img.onload = function () {
                            // setloaded(true)
                            console.log("image is loaded")
                            const imgWidth = img.naturalWidth;
                            const imgHeight = img.naturalHeight;
                            localStorage.setItem("ratio", imgWidth / imgHeight)
                            console.log("ratio needed", imgWidth / imgHeight)
                            let artData = { data: { title: "Custom Framing" }, width: imgWidth, height: imgHeight }
                            localStorage.setItem("artData", JSON.stringify(artData))
                            setpaper("Paper")

                        };
                    })
                .catch(err => {

                })
        }
    }, [params.artid])
    useEffect(() => {
        if (paper) {
            localStorage.setItem("papertype", paper)
            console.log("calling frame with", paper)
            getFrames([], "", paper)
            if (paper === "Paper")
                goldenratio()
            else
                setslider("0")
        }
    }, [paper])
    useEffect(() => {
        if (state.frameList?.data?.length) {
            let frameList = state.frameList.data.filter(x => x.attributes.field_frame_or_image !== "image")
            let frontframe = frameList[0].relationships.field_front.data ? state.frameList.included.filter(item => item.id === frameList[0].relationships.field_front.data.id)[0].attributes.uri.url : "",
                sideframe = frameList[0].relationships.field_side.data ? state.frameList.included.filter(item => item.id === frameList[0].relationships.field_side.data.id)[0].attributes.uri.url : "";
            settingFrame(frameList[0].id, frontframe, sideframe, frameList[0].attributes.title)
        }
    }, [state.frameList])
    const setFrame = async (item) => {
        let rat = item.split("x")
        setdimension(item)
        let load = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
        var artData = localStorage.getItem("artData") ? JSON.parse(localStorage.getItem("artData")) : {}
        console.log("test", artData)
        artData.height = load.height
        artData.width = load.width
        artData.framesize = item;
        localStorage.setItem("artData", JSON.stringify(artData));
        setartdatas(artData)
    }
    const goldenratio = () => {
        // let i = Number(artdatas.width) >= Number(artdatas.height) ? Number(artdatas.width) : Number(artdatas.height)
        let i = Number(artdatas.width)
        console.log("calling golden ratio", artdatas.width, "x", artdatas.height)
        let final
        if (i < 9.9) {
            final = 2
        }
        else if (i >= 9.9 && i < 13.4)
            final = 2.25
        else if (i >= 13.4 && i < 20.4)
            final = 2.5
        else if (i >= 20.4 && i < 22.4)
            final = 2.75
        else if (i >= 22.4) {
            if (Number(artdatas.width) === Number(artdatas.height)) {
                final = 3
            }
            else if (i === 23.9) {
                final = 3
            }
            else {
                final = 3.75
            }
        }
        setslider(`${final}`)
        setPadding(final)
        setThumb(`${final}`)

    }
    const oldgoldenratio = (message) => {
        let area = Number(artdatas.height) * Number(artdatas.width)
        console.log('sizes', artdatas.height, 'X', artdatas.width)
        console.log('area', area)
        let goldenarea = Number(area) * 1.618
        console.log('golden area', goldenarea)
        let sqrt = Math.sqrt(goldenarea)
        console.log('sqrt', sqrt)
        let moundborderwidth = sqrt - Number(artdatas.width)
        let moundborderheight = sqrt - Number(artdatas.height)
        let moundborder = (moundborderwidth + moundborderheight) / 4
        console.log(moundborderwidth, moundborderheight, moundborder)
        // console.log(Math.round(1.6 / 0.5) * 0.5)
        if (moundborder <= 1.5) {
            setslider("1.5")
            setPadding(Number("1.5"))
            setThumb('2')
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "1.5" },
                // { "name": "2", "value": "2" },
                // { "name": "2.5", "value": "2.5" },
                // { "name": "3", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 1.5 && moundborder <= 2) {
            setslider("2")
            setThumb('3')
            setPadding(Number("2"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "2" },
                // { "name": "2.5", "value": "2.5" },
                // { "name": "3", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 2 && moundborder <= 2.5) {
            setslider("2.5")
            setThumb('4')
            setPadding(Number("2.5"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "2.5" },
                // { "name": "3", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 2.5 && moundborder <= 3) {
            setslider("3")
            setThumb('5')
            setPadding(Number("3"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 3 && moundborder <= 3.5) {
            setslider("3.5")
            setThumb('6')
            setPadding(Number("3.5"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 3.5) {
            setslider("4")
            setThumb('7')
            setPadding(Number("4"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "4" }
            ])
        }
        // if (message != "start")
        //     alert.show("Golden ratio applied")
    }
    React.useEffect(() => {
        console.log(`setting final framesize : width: ${artdatas.width}, height: ${artdatas.height} `)
        setfinal(FinalSize({ width: artdatas.width, height: artdatas.height }))
        if (paper == "Paper") {
            if (artdatas.width > artdatas.height) {
                console.log("")
                if (artdatas.width > 52 || artdatas.height > 32) {
                    setPadding(Number("0"))
                    setslider("0")
                    setmatt(false)
                }
                else {
                    setmatt(true)
                    goldenratio("start")
                }
            }
            else {
                if (artdatas.width > 32 || artdatas.height > 52) {
                    setPadding(Number("0"))
                    setslider("0")
                    setmatt(false)
                }
                else {
                    setmatt(true)
                    goldenratio("start")
                }
            }
        }
        else {
            setPadding(Number("0"))
            setslider("0")
            setmatt(false)
        }
    }, [selected])
    function get_url_extension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    })

    const handleScroll = () => {
        var w = window.innerWidth;
        var h = document.getElementById("root").offsetHeight;
        // console.log(window.pageYOffset, h)

        if (h < 740) {
            if (window.pageYOffset > 80) {
                // alert('fixed')
                setSticky(true)
            }
            if (window.pageYOffset === 0)
                setSticky(false)
        }
        else {
            if (window.pageYOffset > 80) {
                // alert('fixed')
                setSticky(true)
            }
            else {
                setSticky(false)
            }
        }
    }
    const calculate = (item, finalsize) => {
        console.log("calculating", finalsize)
        let rat = item.split("x"),
            framerat = finalsize.split("x")
        console.log("ratt", rat)
        let rat2 = Number(rat[0].replace(' ', '')) / Number(rat[1].replace(' ', ''))
        console.log("ratt", rat2)
        let payload = { ratio: rat2, text: item, width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
        let framepayload = { width: Number(framerat[0].replace(' ', '')), height: Number(framerat[1].replace(' ', '')) }
        console.log("ratt2", payload)
        console.log('size selected', item)
        let sqi = payload.width * payload.height
        let framesqi = finalsize ? framepayload.height * framepayload.width : 0
        console.log("framesqi calling with final", finalsize, framesqi)
        type === "frame" ? calculatePrice(framesqi, 'frame', paper, query.get("id")) : calculatePrice(sqi, null, paper)
    }
    useEffect(() => {
        console.log("current padding", state.padding, slidervalue)
    }, [state.padding, slidervalue])
    useEffect(() => {
        if (paper && artInfo) {
            console.log("calling art sizes")
            clearSize()
            calculateNewDPI(artInfo.height, artInfo.width, type, history, "art", paper, artInfo.rheight, artInfo.rwidth)
        }
        else if (paper && query.get("id")) {
            console.log("calling upload sizes")
            clearSize()
            calculateNewDPI(query.get("height"), query.get("width"), type, history, "upload", paper, query.get("height"), query.get("width"))
        }
        console.log("framed container width", (document.body.scrollWidth / 12) * 2.5)
        console.log("framed container ratio", localStorage.getItem('ratio'))
    }, [paper, localStorage.getItem('ratio')], document.body.scrollWidth)
    useEffect(() => {
        if (localStorage.getItem('ratio') && window.innerWidth > 575) {
            let divs = (document.body.scrollWidth / 12) * 2.4
            console.log(`scroll width ${divs} and ratio ${localStorage.getItem('ratio')}`)
            if (localStorage.getItem('ratio') < 0.40)
                paper == "Canvas" ? setValue(120) : setValue(divs * 0.7)
            else if (localStorage.getItem('ratio') >= 0.40 && localStorage.getItem('ratio') < 0.52)
                paper == "Canvas" ? setValue(divs * 0.56) : setValue(divs * 0.8)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                paper == "Canvas" ? setValue(divs * 0.7) : setValue(divs * 1.05)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                paper == "Canvas" ? setValue(divs * 0.8) : setValue(divs * 1.1)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                paper == "Canvas" ? setValue(divs) : setValue(divs * 1.25)
            else if (localStorage.getItem('ratio') >= 1.4)
                paper == "Canvas" ? setValue(divs) : setValue(divs * 1.25)
            console.log("value set", value)
        }
        else if (localStorage.getItem('ratio') && window.innerWidth < 575) {
            let divs = document.body.scrollWidth - 160
            console.log(`scroll width ${divs} and ratio ${localStorage.getItem('ratio')}`)
            if (localStorage.getItem('ratio') < 0.40)
                paper == "Canvas" ? setValue(120) : setValue(100)
            else if (localStorage.getItem('ratio') >= 0.40 && localStorage.getItem('ratio') < 0.52)
                paper == "Canvas" ? setValue(divs * 0.56) : setValue(divs * 0.48)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                paper == "Canvas" ? setValue(divs * 0.7) : setValue(divs * 0.56)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                paper == "Canvas" ? setValue(divs * 0.8) : setValue(divs * 0.75)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                paper == "Canvas" ? setValue(divs) : setValue(divs * 0.73)
            else if (localStorage.getItem('ratio') >= 1.4)
                paper == "Canvas" ? setValue(divs) : setValue(divs)
        }
    }, [paper, localStorage.getItem('ratio'), selected, document.body.scrollWidth])
    React.useEffect(() => {
        if (continuestatus) {
            let props = { width: { "art": artdatas.width, "frame": framesize } }
            console.log("selected width", props.width.art)
            console.log("papertype", localStorage.getItem("papertype"))
            let wid = document.getElementById("wall")?.clientWidth - 30
            let finalwidth = wid * 0.77
            console.log("wall width", finalwidth)
            if (localStorage.getItem("ratio")) {
                if (localStorage.getItem("ratio") == 1) {
                    setOrient("square")
                    //square image
                    if (localStorage.getItem("papertype") == "Paper") {
                        if (props.width.art < 24)
                            setTop("7%")
                        else if (props.width.art = 24)
                            setTop("6%")
                        else
                            setTop("4%")
                    }
                    else {
                        if (props.width.art <= 24)
                            setTop("11%")
                        else if (props.width.art > 24 && props.width.art <= 32)
                            setTop("8%")
                        else
                            setTop("5%")
                    }
                    setLeft("0")
                    setRight("0")
                    setBackImg("/assets/images/backimg3.jpg")//80inch width wall
                    if (window.innerWidth > 1200)
                        setInch(finalwidth / 80)
                    else if (window.innerWidth > 992)
                        setInch(finalwidth / 80)
                    else if (window.innerWidth < 575)
                        setInch((window.innerWidth - 95) / 75)
                    else
                        setInch((window.innerWidth - 95) / 75)
                }
                else if (localStorage.getItem("ratio") < 1) {
                    //vertical image
                    if (localStorage.getItem("papertype") == "Paper") {
                        if (props.width.art <= 18)
                            setTop("7%")
                        else if (props.width.art > 18 && props.width.art <= 26)
                            setTop("5%")
                        else
                            setTop("3%")
                    }
                    setLeft("-25%")
                    setRight("0")
                    setBackImg("/assets/images/backimg.jpg")
                    if (window.innerWidth > 1200)
                        setInch(finalwidth / 60) //60 inch width wall
                    else if (window.innerWidth > 992)
                        setInch(finalwidth / 60)
                    else if (window.innerWidth < 575)
                        setInch((window.innerWidth - 95) / 60)
                    else
                        setInch((window.innerWidth - 95) / 60)
                }
                else {
                    //horizontal image
                    if (localStorage.getItem("papertype") == "Paper") {
                        if (props.width.art / localStorage.getItem("ratio") >= 31)
                            setTop("6%")
                        else if (props.width.art / localStorage.getItem("ratio") < 31 && props.width.art / localStorage.getItem("ratio") >= 26)
                            setTop("7%")
                        else if (props.width.art / localStorage.getItem("ratio") < 26 && props.width.art / localStorage.getItem("ratio") >= 22)
                            setTop("8%")

                        else if (props.width.art / localStorage.getItem("ratio") < 22 && props.width.art / localStorage.getItem("ratio") >= 16)
                            setTop("13%")
                        else
                            setTop("18%")
                    }
                    else {
                        if (props.width.art / localStorage.getItem("ratio") >= 31)
                            setTop("6%")
                        else if (props.width.art / localStorage.getItem("ratio") < 31 && props.width.art / localStorage.getItem("ratio") >= 26)
                            setTop("9%")
                        else if (props.width.art / localStorage.getItem("ratio") < 26 && props.width.art / localStorage.getItem("ratio") >= 22)
                            setTop("10%")

                        else if (props.width.art / localStorage.getItem("ratio") < 22 && props.width.art / localStorage.getItem("ratio") >= 16)
                            setTop("15%")
                        else
                            setTop("20%")
                    }
                    setLeft("0")
                    setRight("11%")
                    setBackImg("/assets/images/backimg2.jpg")
                    if (window.innerWidth > 1200)
                        setInch(finalwidth / 90) //90 inch width wall
                    else if (window.innerWidth > 992)
                        setInch(finalwidth / 90)
                    else if (window.innerWidth < 575)
                        setInch((window.innerWidth - 95) / 90)

                    else
                        setInch((window.innerWidth - 95) / 90)
                }
            }
        }
    }, [state.ratio])
    const getMatt = (item) => {
        // let i = Number(item.width) >= Number(item.height) ? Number(item.width) : Number(item.height)
        let i = Number(item.width)
        let final
        if (i < 9.9) {
            return ("2")
        }
        else if (i >= 9.9 && i < 13.4)
            return ("2.25")
        else if (i >= 13.4 && i < 20.4)
            return ("2.5")
        else if (i >= 20.4 && i < 22.4)
            return ("2.75")
        else if (i >= 22.4) {
            if (Number(item.width) === Number(item.height)) {
                console.log("mat is", 3)
                return ("3")
            }
            else if (i === 23.9) {
                console.log("mat is", 3)
                return ("3")
            }
            else {
                console.log("mat is", 3.75)
                return ("3.75")
            }
        }
    }
    const oldgetMatt = (item) => {
        let area = Number(item.height) * Number(item.width)
        console.log('sizes', item.height, 'X', item.width)
        console.log('area', area)
        let goldenarea = Number(area) * 1.618
        console.log('golden area', goldenarea)
        let sqrt = Math.sqrt(goldenarea)
        console.log('sqrt', sqrt)
        let moundborderwidth = sqrt - Number(item.width)
        let moundborderheight = sqrt - Number(item.height)
        let moundborder = (moundborderwidth + moundborderheight) / 4
        console.log(moundborderwidth, moundborderheight, moundborder)
        if (item.width > item.height) {
            console.log("")
            if (item.width > 52 || item.height > 32) {
                return ("0")
            }
            else {
                if (moundborder <= 1.5) {
                    return ("1.5")
                }
                if (moundborder > 1.5 && moundborder <= 2) {
                    return ("2")
                }
                if (moundborder > 2 && moundborder <= 2.5) {
                    return ("2.5")
                }
                if (moundborder > 2.5 && moundborder <= 3) {
                    return ("3")
                }
                if (moundborder > 3 && moundborder <= 3.5) {
                    return ("3.5")
                }
                if (moundborder > 3.5) {
                    return ("4")
                }
            }
        }
        else {
            if (item.width > 32 || item.height > 52) {
                return ("0")
            }
            else {
                if (moundborder <= 1.5) {
                    return ("1.5")
                }
                if (moundborder > 1.5 && moundborder <= 2) {
                    return ("2")
                }
                if (moundborder > 2 && moundborder <= 2.5) {
                    return ("2.5")
                }
                if (moundborder > 2.5 && moundborder <= 3) {
                    return ("3")
                }
                if (moundborder > 3 && moundborder <= 3.5) {
                    return ("3.5")
                }
                if (moundborder > 3.5) {
                    return ("4")
                }
            }
        }
    }

    const FinalSize = (item) => {
        if (paper) {
            if (paper == "Canvas") {
                if (item) {
                    return (Number(item.width) + 0.80).toFixed(2) + " x " + (Number(item.height) + 0.80).toFixed(2)
                }
            }
            else {
                let slidervalue = getMatt(item)
                // console.log("slider value for arrays:", `${item.width} x ${item.height} is:`, slidervalue)
                if (item) {
                    if (item.width > item.height) {
                        if (item.width <= 17.4) {
                            console.log("final size is", (Number(item.width) + 2 * Number(slidervalue) + 1.60).toFixed(2) + " x " + ((Number(item.height) + (2 * Number(slidervalue))) + 1.60).toFixed(2))
                            return (Number(item.width) + 2 * Number(slidervalue) + 1.60).toFixed(2) + " x " + Math.round((Number(item.height) + (2 * Number(slidervalue))) + 1.60).toFixed(2)
                        }
                        else {
                            return (Number(item.width) + 2 * Number(slidervalue) + 2.10).toFixed(2) + " x " + Math.round(Number(item.height) + (2 * Number(slidervalue)) + 2.10).toFixed(2)
                        }
                    }
                    else {
                        if (item.width <= 17.4) {
                            return (Number(item.width) + 2 * Number(slidervalue) + 1.60).toFixed(2) + " x " + Math.round(Number(item.height) + (2 * Number(slidervalue)) + 1.60).toFixed(2)

                        }
                        else {
                            return (Number(item.width) + 2 * Number(slidervalue) + 2.10).toFixed(2) + " x " + Math.round(Number(item.height) + (2 * Number(slidervalue)) + 2.10).toFixed(2)

                        }
                    }
                }
            }
        }
    }
    useEffect(() => {
        console.log("changed framesize", framesize)
    }, [framesize])
    useEffect(() => {
        if (state.printsizes) {

            let new_sam = state.printsizes
            if (new_sam.length) {
                var rat = new_sam[0].split("x")
                // console.log(rat)
                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
                let finalsize = FinalSize({ width: payload.width, height: payload.height })
                if (finalsize) {
                    console.log("sam is ", new_sam[0], "final:", finalsize)
                    setcontinue(true);
                    setselected(type === "frame" ? finalsize : new_sam[0]);
                    calculate(new_sam[0], finalsize);
                    setRatio(new_sam[0])
                    setFrame(new_sam[0]);
                }
            }
        }
    }, [state.printsizes])
    var limit1, limit2 = 0, limit3, sam1 = [], sam2 = [], sam3 = [], sam4 = [], new_sam = [];

    var sam = [].concat(state.printsizes);



    sam.map((item, ind) => {
        if (item) {
            var rat = item.split("x")
            let payload = { width: Math.round(Number(rat[0].replace(' ', ''))), height: Number(rat[1].replace(' ', '')) }
            if (payload.width >= payload.height) {
                if (payload.width < 14) {
                    // limit1 = ind;
                    sam1.push(item)
                }
                else if ((payload.width >= 14) && (payload.width < 24)) {
                    // limit2 = ind;
                    // console.log("awr" + ind + " " + payload.width)
                    sam2.push(item)
                }
                else if ((payload.width >= 24) && (payload.width < 36)) {
                    // limit3 = ind;
                    sam3.push(item)
                }
                else
                    sam4.push(item)
            }
            else {
                if (payload.height < 14) {
                    // limit1 = ind;
                    sam1.push(item)
                }
                else if ((payload.height >= 14) && (payload.height < 24)) {
                    // limit2 = ind;
                    // console.log("awr" + ind + " " + payload.height)
                    sam2.push(item)
                }
                else if ((payload.height >= 24) && (payload.height < 36)) {
                    // limit3 = ind;
                    sam3.push(item)
                }
                else
                    sam4.push(item)
            }
        }
    })
    sam1 = sam1.reverse();
    sam2 = sam2.reverse();
    sam3 = sam3.reverse();
    sam4 = sam4.reverse();
    new_sam = sam.reverse();
    const checkpin = (code) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (code === '' || re.test(code)) {
            let element = document.getElementById("pincode-input")
            if (element)
                element.classList.remove("error")
            changepin(code)
        }
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                        localStorage.setItem("pincode", code)
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }
    const checkAdd = () => {
        if (!pinstatus) {
            let element = document.getElementById('pincode-input');
            element.classList.add('error');
            element.focus()
            console.log("element", element)
            // if (pin.length == 0) {
            //     alert.show("Please enter a pincode to check delivery availability")
            // }
            // else if (pin.length < 6)
            //     alert.show("Invalid Pin")
            // else {
            //     alert.show("Sorry, the pincode is not serviceable")
            // }
        }
        else if (!continuestatus)
            alert.show("Please choose a size")
        else {
            if (type === "art")
                setDataPrint()
            else
                setDataFramed()
        }
    }
    const setDataPrint = async (msg) => {
        if (state.price) {
            let artData = {
                "framesize": `${Number(state.ratio.width).toFixed(2)} X ${Number(state.ratio.height).toFixed(2)}`,
                "papertype": paper
            }
            setloader(true)
            let custom_data = localStorage.getItem('upload_image_var') ? JSON.parse(localStorage.getItem('upload_image_var')) : null
            artData.user_upload = false
            artData.cropped_image = null
            artData.og_image = null
            artData.field_print_or_frame = "print"
            artData.art_id = state.artData?.data?.data.id ? state.artData.data.data.id : null
            artData.item_id = state.artData?.data?.data.id ? state.artData.data.data.id : null
            artData.item_name = paper ? `Print on ${paper} - ${title ? title : "User Custom Image"}` : ""
            artData.art_varid = state.printVarData ? state.printVarData.id : null
            artData.height = state.ratio.height
            artData.width = state.ratio.width
            artData.image_id = state.artData?.data?.included?.find(x => x.id === state.artData?.data?.data.relationships.field_image?.data?.id)?.relationships.thumbnail?.data?.id
            artData.art_title = artdatas ? title : "User Custom Image"
            artData.art_price = state.price ? state.price : Number(custom_data[0].attributes.price.number)
            artData.total_price = state.price ? state.price : ""
            artData.dimension = (Number(state.ratio.width)).toFixed(2) + `"` + " X " + (Number(state.ratio.height)).toFixed(2) + `"`
            artData.varid = null
            console.log(artData)
            let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
            let checkoutdata = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : { total_no: 0 }
            if (Number(checkoutdata?.total_no) == 29) {
                alert.show("Maximum Cart Limit is 30")
                console.log("maximum achieved")
            }
            if (Number(checkoutdata.total_no) < 30) {
                if (cartData.findIndex(x => x.art_varid == artData.art_varid && x.papertype === artData.papertype && x.field_print_or_frame === "print" && x.framesize === artData.framesize) >= 0) {
                    let i = cartData.findIndex(x => x.art_varid == artData.art_varid && x.papertype === artData.papertype && x.field_print_or_frame === "print" && x.framesize === artData.framesize)
                    if (cartData[i].quantity)
                        cartData[i].quantity = Number(cartData[i].quantity) + 1
                    else
                        cartData[i].quantity = 2
                }
                else {
                    cartData.push(artData)
                }
                localStorage.setItem("cartData", JSON.stringify(cartData));
                if (checkoutdata) {
                    console.log("cart length", cartData.length)
                    checkoutdata.total_no = checkoutdata.total_no ? cartData.length !== 0 ? cartData.length : 1 : 1
                    checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
                    checkoutdata.total_price = checkoutdata.total_price + artData.total_price
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }

                else {
                    let checkoutdata = {
                        "total_no": 1,
                        "total_price": artData.total_price
                    }
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }
                setloader(false)
                msg ? history.push("/checkout/details") :
                    openAdd(true)
            }
            else
                alert.show("Maximum Cart limit reached")
        }

    }
    const setDataFramed = async () => {
        if (state.price) {
            setloader(true)
            console.log("pathname", location.pathname)
            let sqi = artdatas.width * artdatas.height
            let artData = JSON.parse(localStorage.getItem("artData"))
            if (location.pathname === "/custom-framing") {
                artData.user_upload = true
            }
            artData.field_print_or_frame = "frame"
            artData.ratio = localStorage.getItem("ratio") ? localStorage.getItem("ratio") : null
            artData.matting = slidervalue
            artData.height = artdatas.height
            artData.width = artdatas.width
            artData.image_id = location.pathname === "/custom-framing" ? localStorage.getItem("croppedid") : state.artData?.data?.included?.find(x => x.id === state.artData?.data?.data.relationships.field_image?.data?.id)?.relationships.thumbnail?.data?.id
            artData.pincode = pin
            artData.padcolor = state.padColor
            artData.varid = state.frameVarData.id
            artData.art_varid = state.printVarData ? state.printVarData.id : null
            artData.papertype = paper
            artData.title = state.framedata.attributes ? state.framedata.attributes.title : ""
            artData.item_name = location.pathname === "/custom-framing" ? `Custom Framing ${paper}` : `Framed Print on ${paper} - ${artdatas.data.attributes.title}`
            artData.item_id = location.pathname === "/custom-framing" ? localStorage.getItem("croppedid") : state.artData?.data?.data.id ? state.artData.data.data.id : ""
            artData.art_id = location.pathname === "/custom-framing" ? localStorage.getItem("croppedid") : state.artData?.data?.data.id ? state.artData.data.data.id : ""
            artData.artist = artist ? artist : ""
            artData.art_title = params.artid ? title : "User Custom Image"
            artData.cropped_image = location.pathname === "/custom-framing" ? localStorage.getItem("croppedid") : null
            artData.og_image = location.pathname === "/custom-framing" ? localStorage.getItem("uploadid") : null
            artData.qty = Math.ceil(sqi)
            artData.price = state.price
            artData.total_price = state.price
            artData.scales = location.pathname === "/custom-framing" ? JSON.parse(localStorage.getItem('scales')) : null
            if (artData) {
                artData.framesize = finalsize.replace("x", "X")
                artData.height = Number(artdatas.height).toFixed(2)
                artData.width = Number(artdatas.width).toFixed(2)
                artData.dimension = `${(Number(artdatas.width).toFixed(2))}" X ${(Number(artdatas.height).toFixed(2))}"`
                console.log('artData after frame edit', artData)
                let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
                setloader(false)
                // cart should'nt exceed 30 element
                let checkoutdata = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : { total_no: 0 }
                if (Number(checkoutdata?.total_no) == 29) {
                    alert.show("Maximum Cart Limit is 30")
                    console.log("maximum achieved")
                }
                if (Number(checkoutdata.total_no) < 30) {
                    if (cartData.findIndex(x => x.art_varid == artData.art_varid && x.papertype === artData.papertype && x.field_print_or_frame === "frame" && x.varid === artData.varid && x.framesize === artData.framesize && x.user_upload == artData.user_upload) >= 0) {
                        let i = cartData.findIndex(x => x.art_varid == artData.art_varid && x.papertype === artData.papertype && x.field_print_or_frame === "frame" && x.varid === artData.varid && x.framesize === artData.framesize && x.user_upload == artData.user_upload)
                        if (cartData[i].quantity)
                            cartData[i].quantity = Number(cartData[i].quantity) + 1
                        else
                            cartData[i].quantity = 2
                    }
                    else {
                        cartData.push(artData)
                    }
                    localStorage.setItem("cartData", JSON.stringify(cartData));
                    let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
                    // let checkoutdata = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : ""
                    if (checkoutdata) {
                        checkoutdata.total_no = checkoutdata.total_no ? checkoutdata.total_no + 1 : 1
                        checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
                        checkoutdata.total_price = checkoutdata.total_price + artData.total_price
                        localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                        cartTrig(state.cartTrigger + 1)
                    }

                    else {
                        let checkoutdata = {
                            "total_no": 1,
                            "total_price": artData.total_price
                        }
                        localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                        cartTrig(state.cartTrigger + 1)
                    }
                    setloader(false)
                    openAdd(true)
                }
                else
                    alert.show("Maximum Cart limit reached")
            }
            console.log('addcart artdata', artData)
        }
    }
    const settingType = (type) => {
        localStorage.setItem("artmode", type);
        setType(type);
        clearSize()
        if (artInfo) {
            console.log("if is working on setting type")
            calculateNewDPI(artInfo.height, artInfo.width, type, history, "art", paper, artInfo.rheight, artInfo.rwidth)
            setpaper("Canvas");
        }
        else if (query.get("id")) {
            console.log("else if is working on setting type")
            calculateNewDPI(query.get("height"), query.get("width"), type, history, "upload", paper, query.get("height"), query.get("width"))
            setpaper("Paper");
        }
        setcontinue(false)
    }

    const settingPaper = (papertype) => {
        setpaper(`${papertype}`);
        localStorage.setItem("papertype", `${papertype}`);
        setcontinue(false)
        settingFrame("", "", "", "")
    }
    const settingFrame = (id, front, side, name) => {
        console.log("calling setting frame", id)
        getSingleFrame(id, "")
        setFrames(front, side)
        setFrameid(id)
        setFrameName(name)
    }

    const addWish = () => {
        let id = state.printVarData?.id
        setloader(true)
        PContext.addToWish(id)
            .then(res => {
                if (res === "item added") {
                    alert.show("Added to Wishlist", { type: "success" });
                    PContext.checkWish(artdatas.data?.id, "art")
                        .then(resp => {
                            setListed(resp)
                        })
                        .catch(err => {
                            console.error("error on check in wish", err)
                        })
                    setloader(false)
                }
            })
            .catch(err => {
                if (err === "logged_out") {
                    alert.show("Login to add to wishlist")
                }
                console.log('error on adding to wishlist', err)
                setloader(false)
            })
    }
    const deleteWish = (id) => {
        setloader(true)
        PContext.deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    alert.show("Removed from Wishlist", { type: "success" });
                    PContext.checkWish(artdatas.data?.id, "art")
                        .then(resp => {
                            setListed(resp)
                        })
                        .catch(err => {
                            console.error("error on check in wish", err)
                        })
                    setloader(false)
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    return (
        <div className="shopArt-typeSelect">
            {loader ?
                <Loader />
                : ''}
            <Header />
            {artdatas?.data ? artdatas.data.attributes?.metatag_normalized ? artdatas.data.attributes.metatag_normalized.length ?
                <MetaTags>
                    {artdatas.data.attributes.metatag_normalized.map((item) => {
                        if (item.tag === "meta")
                            if (item.attributes.name === "title")
                                return <title>{item.attributes.content.replace(`${meta_artist}`, `${artist.replace(",", " ")}`)}</title>
                            else
                                return <meta name={`${item.attributes.name}`} content={`${item.attributes.content.replace(`${meta_artist}`, `${artist.replace(",", " ")}`)}`} />
                        else if (item.tag === "link") {
                            if (item.attributes?.rel === "canonical")
                                return <link rel={`${item.attributes.rel}`} href={`${window.location.href}`} />
                            else
                                return <link rel={`${item.attributes.rel}`} href={`${item.attributes.href}`} />
                        }
                    })}
                    <meta property="og:type" content="og:product" />
                    <meta property="og:title" content={artdatas.data ? artdatas.data.attributes.title : ""} />
                    <meta property="og:image" content={localStorage.getItem('showimage')} />
                    <meta property="og:url" content={`/shop_art_type/${params.artid}`} />
                    <meta property="og:site_name" content="Espravo" />
                    <meta name="twitter:card" content="" />
                    <meta name="twitter:site" content="@twitterid" />
                    <meta name="twitter:title" content={artdatas.data ? artdatas.data.attributes.title : ""} />
                    <meta name="twitter:image" content={localStorage.getItem('showimage')} />

                </MetaTags>
                : "" : '' : ""
            }
            <div className='header-container'>
                <Link className="mb-1 selectBack brandon d-flex align-items-center mt-3" to={window.location.pathname === "/custom-framing" ? "/crop" : localStorage.getItem("art_back_path") ? localStorage.getItem("art_back_path") : "/art/"} onClick={() => { window.location.pathname === "/custom-framing" ? localStorage.setItem("artmode", "frame") : localStorage.setItem("artmode", "shopart") }}>
                    <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </Link>
            </div>
            <div className="container mb-5 pb-5">
                {/* <h2 className="artPop-title bold py-md-5 py-3 GT mb-0 text-center secondarycolor">
                    Shop Art Prints
                </h2> */}
                <div className="row py-md-5 py-3">

                    <div id="frame_preview" className="col-12 pl-sm-0 col-sm-6 h-auto ">
                        <div className='d-none'>{state.printVarData ? state.printVarData.id : "Not right"}</div>
                        {state.artData ? <div className={`image-preview-container ${sticky ? "" : ""} ${type == "frame" ? "main" : "no-frame"}`} style={{ top: sticky ? "72px" : 0 }}>
                            {type == "frame" ? value ?
                                <div className='frame-edit-preview-container flex-column'>
                                    <FrameEditPreview place={"single_page"} image={state.cropImg} value={value} frame={state.frontFrame} framing={{ "type": location.pathname === "/custom-framing" ? "upload" : "art", "depth": artdatas?.width, "papertype": paper, "frame": paper == "Canvas" ? artdatas?.width > 24 ? (framesize + 0.8) : (framesize + 0.6) : framesize }} />
                                    <Tools title={title} addWish={addWish} deleteWish={deleteWish} listed={listed} openZoom={zoomOpen} image={state.cropImg} />
                                </div>
                                : "" :
                                <div className='frame-edit-preview-container flex-column'>
                                    <div className='p-4 w-100 h-100'>
                                        <img src={state.cropImg} className='frame-edit-preview show-img' />
                                    </div>
                                    <Tools title={title} addWish={addWish} deleteWish={deleteWish} listed={listed} openZoom={openZoom} image={state.cropImg} />
                                </div>
                            }
                            <h1 className="second-heading mt-sm-4 mt-3 mb-1 mt-1 text-center">
                                {title}
                            </h1>
                            <h2 className="brandon sub-title pl-0 text-center mb-sm-4 mb-0">{artist ? artist.replace(",", " ") : ""}</h2>
                        </div>
                            : type == "frame" ? value ?
                                <div className='frame-edit-preview-container flex-column'>
                                    <FrameEditPreview place={"single_page"} value={value} frame={state.frontFrame} framing={{ "depth": artdatas?.width, "papertype": paper, "frame": paper == "Canvas" ? artdatas?.width > 24 ? (framesize + 0.8) : (framesize + 0.6) : framesize }} />
                                    <div className="d-flex w-100 justify-content-center align-items-center tools">
                                        {/* <Share className="mb-0" imageClassName="mb-0" title={title} />
                                        <a href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}`}
                                            data-pin-do="buttonPin"
                                            data-pin-custom="true"
                                            target="_blank"
                                        >
                                            <img src="/assets/push-pin.png" height="28px" width="28px" />
                                        </a> */}
                                        <img src="/assets/zoom.png" className="zoom-button cursor" onClick={() => openZoom(true)} />
                                    </div>
                                </div>
                                : "" :
                                <div className='frame-edit-preview-container'>
                                    <div className='p-4 w-100 h-100'>
                                        <img src={localStorage.getItem('showimage')} className='frame-edit-preview show-img' />
                                    </div>
                                    <Share title={title} />
                                    <div className="zoom-button-container" onClick={() => zoomOpen(true)}>
                                        <img src="/assets/zoom.png" className="zoom-button" onClick={() => openZoom(true)} />
                                    </div>
                                </div>
                        }
                    </div>
                    {artdatas?.data ? <div className="col-12 col-sm-6 complete_flow-details">
                        {artdatas.data ?
                            window.location.pathname !== "/custom-framing" ?
                                <div className="typeSelect-section">
                                    <h4 className="second-heading col-12 mt-3 mt-sm-0">Choose Framed or Print</h4>
                                    {/* <p className="brandon sub-title mt-1 col-12">Our art prints are available framed or unframed. Select your prefered option.</p> */}
                                    <div className='d-flex col-12 mx-auto type-select-box'>
                                        <div className={`text-center cursor type-select-single-box ${type === "frame" ? "active" : ""}`} onClick={() => { settingType("frame"); }}>
                                            <p className="brandon mt-1">Framed</p>
                                        </div>
                                        <div className={`text-center cursor type-select-single-box ${type === "art" ? "active" : ""}`} onClick={() => { settingType("art"); }}>
                                            <p className="brandon mt-1">Print</p>
                                        </div>
                                    </div>
                                </div> : ""
                            : <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} />}

                        <div ref={ref1} />
                        {type ? <div className="typeSelect-section">
                            <h4 className="second-heading col-12">Choose a Media</h4>
                            {/* <p className="brandon sub-title mt-1 col-12">This art prints are available Canvas and Paper. Choose your art medium.</p> */}
                            <div className="brandon type-select-box col-12 d-flex mx-auto">
                                <div className={`paper-type ${paper === "Canvas" ? "active" : ""}`} onClick={() => { settingPaper("Canvas"); }}>
                                    <p>Canvas</p>
                                </div>
                                <div className={`paper-type ${paper === "Paper" ? "active" : ""}`} onClick={() => { settingPaper("Paper"); }}>
                                    <p>Paper</p>
                                </div>
                            </div>
                        </div> : ""}
                        <div ref={ref2} />
                        {paper && type === "frame" ? <div className="typeSelect-section">
                            <h4 className="second-heading col-12">Frame Style</h4>

                            {frameid ? <p className="brandon sub-title mt-1 col-12">
                                Selected Frame: {framename}
                            </p> : ""}
                            <div className="col-12 d-flex flex-wrap frame-select-container">
                                {state.frameList?.data?.length ?
                                    state.frameList.data.map((frame, ind) => {
                                        let framethumb = frame.relationships.field_side.data ? state.frameList.included.filter(item => item.id === frame.relationships.field_side.data.id) : "",
                                            frontframe = frame.relationships.field_front.data ? state.frameList.included.filter(item => item.id === frame.relationships.field_front.data.id)[0].attributes.uri.url : "",
                                            sideframe = frame.relationships.field_side.data ? state.frameList.included.filter(item => item.id === frame.relationships.field_side.data.id)[0].attributes.uri.url : ""
                                        if (frame.attributes.field_frame_or_image !== "image")
                                            return <div className={`frame-select-box-container ${frameid === frame.id ? "active" : ""}`} onMouseEnter={() => hoverframe(frame.id)} onMouseLeave={() => hoverframe("")} onClick={() => { settingFrame(frame.id, frontframe, sideframe, frame.attributes.title); }}>
                                                <img className="frame-thumbnail" src={framethumb ? base_url + framethumb[0].attributes.uri.url : ""} />
                                                {hoveredframe === frame.id ? <div className="frame-hover-box brandon">
                                                    <img className="frame-hover-box-arrow" src="/assets/caption-box-arrow.png" />
                                                    <div className="brandon text">{frame.attributes.title}</div>
                                                </div> : ""}
                                            </div>
                                    }) : <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={30} />}
                            </div>

                        </div> : ""}
                        <div ref={ref3}></div>
                        {frameid || (paper !== "" && type === "art") ?
                            state.printsizes ?

                                <div className="typeSelect-section">
                                    <h4 className="second-heading col-12">Select a Size (Inches)</h4>
                                    {/* <p className="brandon sub-title mb-0 mt-1 col-12">This art prints are available multiple sizes. Choose your preferred size.</p> */}
                                    {state.printsizes.length ? <div className="col-12 mx-auto">
                                        <div className="w-100 odd mx-auto row">
                                            {state.printsizes.map((item, ind) => {
                                                var rat = item.split("x")
                                                // console.log(rat)
                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
                                                let finalsize = FinalSize({ width: payload.width, height: payload.height })
                                                return (
                                                    <div style={{ marginRight: ind == sam.length - 1 || (ind + 1) % 3 == 0 ? "0%" : "" }} onClick={() => { setRatio(item); setcontinue(true); setselected(type === "frame" ? finalsize : item); calculate(item, finalsize); setFrame(item); }} className={continuestatus && (dimension == item) ? "size-tab  selected" : "size-tab"}>
                                                        {type === "frame" ? finalsize : (payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    </div> : ""}
                                </div>

                                : <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} />
                            : ""}
                        {/* <div className="col-12 d-flex text-center">
                            {paper ? localStorage.getItem("artmode") == "frame" ?
                                <Link to={continuestatus ? `/frame/${params.artid}?type=art` : `/shop_art_type/${params.artid}`} className="choose-frame-button brandon text-uppercase py-2 bgsecondary whitecolor" onClick={() => continuestatus ? setData() + localStorage.setItem("back", `/shop_art/${params.artid}`) : alert.show("Please choose a size")}>
                                    CONTINUE <img className="continue-arrow" src="/assets/white-arrow.png" />
                                </Link>
                                : "" : ''}
                        </div> */}
                        {paper ? <div className="input-pin mb-3 col-12">
                            <input id="pincode-input" className='brandon pincode-input w-100' value={pin} onChange={(e) => checkpin(e.target.value)} placeholder="Enter Pincode For Delivery Availability" />
                            {
                                pin.length ? pin.length === 6 ?
                                    <p className={pinstatus && pin.length === 6 ? "green brandon" : "red brandon"}>{pinstatus && pin.length === 6 ? "Delivery in 5-7 days" : "Delivery Unavailable"}</p>
                                    : '' : ''
                            }
                        </div> : ""}
                        {paper ? localStorage.getItem("artmode") == "art" ? <div className="container text-center">
                            {/* <p className="frame-price headmedium">
                            {state.price && continuestatus ? <span>₹ <Price value={state.price} /></span> : ''}
                        </p> */}
                        </div> : "" : ''}
                        <div ref={ref4}></div>
                        {continuestatus ? <div className="print-check col-12 d-flex justify-content-space-between">
                            <p className="frame-price add-price brandon my-auto headmedium">
                                {state.price && continuestatus ? <span>Rs. <Price value={state.price} /></span> : ''}
                            </p>
                            <div className="d-flex justify-content-end button ml-auto pr-0">
                                <div onClick={() => checkAdd()} className="shop-art-button add-cart-button brandon text-uppercase mr-sm-2 bgsecondary whitecolor">
                                    Add To Cart <img src="/assets/white-arrow.png" className="add-cart-arrow" />
                                </div>
                                {/* <button onClick={() => continuestatus && pinstatus ? setDataPrint("checkout") : alert.show("Please choose a size")} className="shop-art-button brandon text-uppercase mx-auto py-2 px-sm-4 px-0 bgsecondary whitecolor">
                                Checkout
                            </button> */}
                            </div>
                        </div> : ""}
                        {type === "frame" ? <div className='col-12 pt-2 pt-sm-3'>
                            <Review return={policy} care={care?.find(x => x.type === `${location.pathname === "/custom-framing" ? "custom" : "art"}_${paper}`)?.data} />
                        </div> : ""}
                    </div> :
                        <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} />
                    }
                </div>
                {zoom ? <div className='overlay zoom-image-overlay'></div> : ""}
                <Popup className="zoompopup" open={zoom} onClose={() => openZoom(false)}>
                    <div className="d-flex zoompopup-container flex-column ">
                        <img className="zoom-close cursor ml-auto mb-3 d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openZoom(false)} />
                        <div className='p-relative'>
                            <img src={localStorage.getItem('showimage')} className='zoom-img' />
                            {/* <img className='water-mark' src='/assets/watermark.png' /> */}
                        </div>
                    </div>
                </Popup>
                {state.zoom ? <div className='overlay zoom-image-overlay'></div> : ""}
                {artdatas?.data ? <Popup className="zoompopup" open={state.zoom} onClose={() => zoomOpen(false)}>
                    <div className='d-flex zoompopup-container flex-column' style={{ width: `100%` }}>
                        <img className="zoom-close cursor ml-auto mb-3 d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => zoomOpen(false)} />
                        {window.innerWidth < 575 ?
                            <FramePopup place={"single_page"} image={state.cropImg} value={value * 1.12} padding={paper == "Canvas" ? 1 : ""} framing={{ "depth": artdatas.width, "papertype": paper, "frame": paper == "Canvas" ? artdatas.width >= 40 ? (framesize + 1) : artdatas.width < 40 && artdatas.width > 24 ? (framesize + 0.8) : artdatas.width <= 16 ? (framesize + 0.4) : (framesize + 0.6) : framesize }} />
                            : <FramePopup place={"single_page"} image={state.popImg} value={value * 1.30} padding={paper == "Canvas" ? 1 : ""} framing={{ "depth": artdatas.width, "papertype": paper, "frame": paper == "Canvas" ? artdatas.width >= 40 ? (framesize + 1) : artdatas.width < 40 && artdatas.width > 24 ? (framesize + 0.8) : artdatas.width <= 16 ? (framesize + 0.4) : (framesize + 0.6) : framesize }} />
                        }
                    </div>
                </Popup> : ""}
                <Popup open={add} onClose={() => openAdd(false)}>
                    <div className="alert-popup">
                        <img className="zoom-close cursor ml-auto d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openAdd(false)} />
                        <div className="GT alert-heading">
                            Added to cart
                        </div>
                        <p className="brandon alert-desc">
                            Product successfully added to your shopping cart.
                        </p>
                        <div className="d-flex">
                            <div className="alert-continue cursor py-2 brandon" onClick={() => openAdd(false)}>Continue shopping</div>
                            <div className="alert-proceed py-2 cursor brandon" onClick={() => { history.push('/checkout/shopping-bag/'); }}>Proceed to checkout</div>
                        </div>
                    </div>
                </Popup>
            </div>
            <Footer />

        </div>
    )
}
export default TypeSelect;